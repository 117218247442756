/*--------------------------------------------------
Home
--------------------------------------------------*/

.home {
    width: 80% !important;
    margin-left: 16% !important;
    color: var(--text-color);
    font-family: Roboto;
}

@media only screen and (max-width: 767px) { 
    .home {
        width: 100% !important;
        margin-left: 0% !important;
    }
}

.about-section {
    margin-bottom: 7%;
}

.text-bubble {
    background-image: url('../Images/fun-thing.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 6%;
    width: 100%;
    filter: drop-shadow(2px 8px 6px rgba(0, 0, 0, 0.6));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bubble-hr {
    width: 60%;
    border-top: #e2dfcf 2px solid;
    opacity: 1;
}

.section-hr {
    width: 40% !important;
    border-top: #e2dfcf 2px solid;
    opacity: 1;
}

.skills-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills-bubble hr {
    width: 50% !important;
    border-top: #121415 2px solid;
    opacity: 1;
}

.other-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.other-bubble hr {
    width: 50%;
    border-top: #121415 2px solid;
    opacity: 1;
}

.text-bubble h1 {
    font-weight: 800;
    font-size: 2.5vi;
    text-align: center;
}

.text-bubble p { 
    font-size: 1vi;
    text-align: center;
    padding: 4%;
}

@media only screen and (max-width: 767px) { 
    .text-bubble {
        padding: 20%;
    }
    .text-bubble h1 { 
        font-size: 6vi;
    }
    .text-bubble p { 
        font-size: 2.8vi;
        padding-left: 10%;
        padding-right: 10%;
    }
}

.bubble1 { 
    background-image: url('../Images/fun-small-thing.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    filter: drop-shadow(2px 8px 6px rgba(0, 0, 0, 0.9));
}

.skills-bubble h2 {
    color: #121415;
    text-align: center;
    margin-top: 2%;
    font-weight: bold;
}

.logo-container {
    width: 70% !important;
}

.skill-logo {
    padding: 4%;
    width: 100%;
    height: 5vh;
    margin-bottom: 10%;
}

@media only screen and (max-width: 767px) { 
    .bubble1 { 
        display: block; 
    }
    .skills-bubble h2 { 
        font-size: 4.2vi;
    }
    .skills-bubble hr {
        width: 30% !important;
    }
    .logo-container {
        width: 55% !important;
        padding-bottom: 6%;
    }
    .skill-logo { 
        height: 5vh;
    }
}

.bubble2 {
    background-image: url('../Images/fun-small-thing.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    filter: drop-shadow(2px 8px 6px rgba(0, 0, 0, 0.9));
}

.other-bubble-info {
    color: #121415;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.other-bubble-info h2 { 
    font-size: 1vi;
}

.other-bubble-info p { 
    font-size: 0.6vi;
}

.other-bubble-info-row {
    width: 100% !important;
    margin-bottom: 6%;
}

.other-bubble-info img {
    width: 100%;
    cursor: pointer;
}

.other-bubble h2 {
    color: #121415;
    margin-top: 92%;
    text-align: center;
    font-weight: bold;
}

.float-right {
    float: right;
}

@media only screen and (max-width: 767px) { 
    .bubble2 { 
        background-position: top;
    }
    .other-bubble h2 { 
        margin-top: 5%;
    }
    .other-bubble-info img { 
        width: 100%;
    }
    .other-bubble-info h2 { 
        font-size: 2.75vi;
        width: 100% !important;
    }
    .other-bubble-info p { 
        font-size: 2.2vi;
    }
    .float-right { 
        float: none;
    }
}

.project-section {
    margin-bottom: 7%;
    background-color: #121415;
    box-shadow: 5px 10px 4px rgba(0, 0, 0, 0.7);
    border-radius: 5%;
    padding: 4%;
}

.project-section h1 {
    text-align: center;
    font-size: 2.5vi;
    font-weight: 800;
    margin-bottom: 2% !important;
}

.project-swiper {
    height: 100%;
    width: 100%;
    padding: 2% !important;
}

.project-swiper .slide {
    position: relative;
    background-color: #121415;
    text-align: center;
    border-radius: 10%;
    padding: 2%;
    box-shadow: 5px 6px 4px rgba(0, 0, 0, 0.7);
    border: #e2dfcf 4px solid;
    height: 52vh !important;
    transition: all 0.2s ease-in-out !important;
    cursor: pointer;
}

.project-swiper .slide:hover {
    padding: 1.5%;
    box-shadow: 2px 10px 8px #e2dfcf;
}

.project-swiper .slide img {
    width: 80%;
    height: 13vi;
    border: #e2dfcf 4px solid;
}

.project-title {
    width: 100%;
    background-color: #e2dfcf;
    color: #121415;
    text-decoration: none !important;
    font-size: 1.5vi !important;
    border-radius: 10%;
    padding: 1%;
}

.description {
    margin-top: 2%;
    font-size: 0.9vi;
}

.extra-details {
    display: flex;
    gap: 5%;
    justify-content: center;
    font-style: italic;
}

.swiper-buttons {
    margin-top: 2% !important;
    width: 13% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15%;
    background-color: #e2dfcf;
    padding: 2%;
    border-radius: 15%;
}

.next, .prev {
    cursor: pointer;
    width: 30%;
}

@media only screen and (max-width: 767px) {  
    .project-section {
        margin-left: 1% !important;
        margin-right: 1% !important;
    }
    .project-section h1{ 
        font-size: 5vi;
    }
    .project-swiper { 
        padding: 5% !important;
    }
    .project-swiper .slide { 
        padding: 3%;
        height: 35vh !important;
        font-size: 3.5vi;
    }
    .project-swiper .slide:hover {
        padding: 2.5%;
    }
    .project-swiper .slide img { 
        height: 25vi;
    }
    .project-title { 
        font-size: 4vi !important;
    }
    .swiper-buttons { 
        width: 30% !important;
    }
    .extra-details { 
        font-size: 3vi;
    }
    .description { 
        font-size: 2.7vi;
    }
}

.resume-section {
    position: relative;
    background-color: #121415;
    box-shadow: 5px 10px 4px rgba(0, 0, 0, 0.7);
    border-radius: 5%;
    height: 140vh;
    width: 100%;
    padding: 2%;
    box-sizing: border-box;
}

.resume-section h1 {
    text-align: center;
    margin-bottom: 2%;
    font-size: 2.5vi;
    font-weight: 800;
}

.resume {
    margin-top: 1% !important;
    height: 85% !important;
}

@media only screen and (max-width: 767px) {  
    .resume-section { 
        margin-left: 0.5% !important;
        height: 50vh;
    }
    .resume-section h1 { 
        font-size: 5vi;
    }
    .resume { 
        bottom: 3%;
        position: absolute;
        width: 100% !important;
        height: 43vh !important; 
    }
}
