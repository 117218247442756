/*--------------------------------------------------
Generic CSS for initial components
--------------------------------------------------*/

:root {
  --site-background: #8d8d79;
  --text-color: #e2dfcf;

  --navbar-background: #121415;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: var(--site-background);
  color: var(--text-color);
}

/*--------------------------------------------------
Sidebar
--------------------------------------------------*/

.side-bar {
  background-color: var(--navbar-background);
  position: fixed;
  left: 0;
  width: 13% !important;
  height: 100%;
  padding: 1%;
  color: var(--text-color);
  font-family: Roboto;
  text-align: center;
}

@media only screen and (max-width: 767px) { 
  .side-bar {
    display: none;
  }
}

.side-bar img {
  width: 50%;
  border-radius: 50%;
}

.side-bar hr {
  opacity: 1;
  width: 98%;
  border-top: #e2dfcf 2px solid;
}

.side-bar h1 {
  font-size: 2vi;
  font-weight: 800;
}

.side-bar h2 {
  font-size: 1.8vi;
  margin-top: 5%;
  width: 100%;
  padding: 10%;
  border-radius: 5%;
  transition: color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.side-bar h2:hover {
  background-color: var(--text-color);
  color: var(--navbar-background);
  cursor: pointer;
}

.side-bar h3 {
  font-size: 0.75vi;
  font-style: italic;
  font-weight: 700;
}

.side-bar h4 {
  font-size: 1.5vi;
  margin-top: 10%;
  text-decoration: underline;
}

.side-bar a {
  font-size: 0.9vi;
  color: #e2dfcf;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20%;
  margin-top: 2%;
}

.icons a {
  color: var(--text-color);
}

.icons span {
  font-size: 2.75vi;
  cursor: pointer;
}

/*--------------------------------------------------
Navbar
--------------------------------------------------*/

.nav-bar {
  display: none !important;
  background-color: var(--navbar-background);
  color: var(--text-color);
  font-family: Roboto;
  text-align: center;
}

@media only screen and (max-width: 767px) { 
  .nav-bar {
    display: flex !important;
  }
}

.nav-bar-contents img {
  margin-top: 17%;
  width: 60%;
  border-radius: 50%;
}

.nav-bar-contents h1 {
  margin-top: 10%;
  font-size: 4vi;
  font-weight: 800;
}

.nav-bar-contents h2 {
  font-size: 2.5vi;
  margin-top: 5%;
  width: 100%;
  padding: 10%;
  border-radius: 5%;
  transition: color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}
.nav-bar-contents h2:hover {
  background-color: var(--text-color);
  color: var(--navbar-background);
  cursor: pointer;
}

.nav-bar-contents h4 {
  font-size: 3.7vi;
  margin-top: 10%;
  text-decoration: underline;
}

.nav-bar-contents p {
  margin-bottom: 0;
}

.nav-bar-contents a {
  font-size: 2.5vi;
  color: #e2dfcf;
}

.nav-bar-contents .icons {
  margin-top: 12%;
  display: flex;
  flex-direction: column;
}

.nav-bar-contents .icons a {
  padding: 4%;
  color: var(--text-color);
}

.nav-bar-contents .icons span {
  font-size: 4vi;
  cursor: pointer;
}

/*--------------------------------------------------
Project Modal
--------------------------------------------------*/

.modal-content {
  background-color: #121415 !important;
  font-family: Roboto;
  color: var(--text-color) !important;
  border: none !important;
  display: block !important;
}

.modal-title {
  font-size: 2vi !important;
  font-weight: bold !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.btn-close {
  background-color: #e2dfcf !important;
}

.modal-title {
  text-align: center !important;
}

.project-video {
  width: 80%;
  height: 40vh;
}

.project-image-carousel {
  width: 100%;
}

.project-image {
  width: 80%;
  height: 35vh;
  border: var(--text-color) 4px solid;
}

.project-role {
  text-align: center;
  text-decoration: underline;
  margin-top: 3%;
}

.project-description {
  font-size: 1vi;
  margin-top: 3%;
  margin-bottom: 3%;
}

.project-takeaway {
  font-size: 1vi;
  margin-bottom: 3%;
}

.project-link {
  font-size: 1.25vi;
}

.project-skills-container {
  display: flex;
  flex-direction: column;
  align-content: center !important;
}

.project-skills {
  display: flex;
  justify-content: center;
  align-self: center !important;
  width: 50% !important;
  gap: 5%;
}

.project-skill {
  border: none !important;
  width: 4vw;
  margin-top: 3%;
  margin-bottom: 3%;
}

@media only screen and (max-width: 767px) {  
  .modal-title { 
    font-size: 4vi !important;
  }
  .project-video { 
    height: 25vh;
    width: 90%;
  }
  .project-image { 
    height: 20vh;
  }
  .project-description { 
    font-size: 2.75vi;
  }
  .project-takeaway { 
    font-size: 2.75vi;
  }
  .project-link { 
    font-size: 3vi;
  }
  .project-skills { 
    width: 60% !important;
  }
  .project-skill { 
    width: 7vw;
  }
}